<template>
  <div>
    <headers />
      <div class="mb row" style="padding:15px;padding-top: 90px !important;">
      <div class="col-12">
      
        <div class="tab-box" >
                                    <ul class="tab-head nav nav-tabs">
                                      <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.building_info" ></div></a>
                                      </li>
                                      <li  class="nav-item" data-id="tc2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.lease_info" ></div></a>
                                      </li>  
                                      <li class="nav-item" data-id="tc5" @click="tab('tc5')" v-bind:class="[curindex=='tc5' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.building_office_rent" ></div></a>
                                      </li> 
                                      <li class="nav-item" data-id="tc3" @click="tab('tc3')" v-bind:class="[curindex=='tc3' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.rent" ></div></a>
                                      </li> 
                                     <li class="nav-item" data-id="tc4" @click="tab('tc4')" v-bind:class="[curindex=='tc4' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.rent_after" ></div></a>
                                      </li> 
                                     
                                    </ul>
                                    
                                </div>
      </div>
   
     
      <div class="tab-content" style="padding: 15px;background:#fff">
          <h4 class="header-title">{{ paramName }}</h4>
          <router-link :to="{path:tolink}" class="btn btn-primary waves-effect waves-light btn-back-fixed" v-if="cur_space_id=='' || curindex!='tc5' ">
          <i class="mdi mdi-arrow-left-bold"></i> 一覧
          </router-link>
          <button class="btn btn-primary waves-effect waves-light btn-back-fixed" v-if="cur_space_id!='' && curindex=='tc5'" @click="backRentList()"><i class="mdi mdi-arrow-left-bold"></i> 一覧</button>
          <div class="embed-responsive-16by9 tab-pane show active tc2"  v-if="curindex=='tc2'">
              <div class="col-12">
                <div class="card-box">
                      <div class="responsive-table-plugin" v-if="!jsonErr5">

                        <div class="table-rep-plugin">
                          <div class="table-responsive">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th v-for="(tableHeader,key) in tableHeaders" :key="key" :class="'table-row-' + tableHeader" v-show="checkKeys2.includes(key)">{{ tableHeader }}</th>
                                  
                                </tr>
                              </thead>
                              <tbody v-if="jsonObj2.count && checkKeys2.length">
                                  <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)" @child="toggleFocus(idNum)">
                                    
                                    <list-table-row :row="obj" :checkList="checkKeys2" />
                                    
                                  </tr>
                                </tbody>
                            </table>
                            <div class="p-2">
                              <code v-if="!jsonObj2.total_count">表示データがありません。</code>
                            </div>
                          </div> <!-- end .table-responsive -->
                        </div> <!-- end .table-rep-plugin-->

                        <div class="d-flex justify-content-end mt-1" v-if="jsonObj2.total_count">
                          <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
                          &nbsp;~&nbsp;
                          <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj2.count }}</span>
                          &nbsp;/&nbsp;
                          <span>{{ jsonObj2.total_count }}</span>
                        </div>
                        <div class="d-flex justify-content-end mt-1" v-else>
                          <span>0</span>
                          &nbsp;~&nbsp;
                          <span>0</span>
                          &nbsp;/&nbsp;
                          <span>0</span>
                        </div>

                        <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1" v-if="jsonObj.total_count">
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('first')">«</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a></li>
                          <li class="footable-page mb-1" :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }" v-for="num in maxPage" :key="num"><a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('next')">›</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('last')">»</a></li>
                        </ul>
                      </div> <!-- end .responsive-table-plugin-->
                      <div v-else>
                        <code>
                        データ取得に失敗しました。
                        </code>
                      </div>
                </div>
              </div>
          </div>
          <div class="embed-responsive-16by9 tab-pane show active tc3"  v-if="curindex=='tc3'">
              <div class="col-12">
                <div class="card-box">
                     
                </div>
              </div>
          </div>
          <div class="embed-responsive-16by9 tab-pane show active tc4"  v-if="curindex=='tc4'">
          <div class="p-2" v-if="chart_data.chart_nds==undefined"><div class="lang" v-html="lan.no_data"></div></div>
           <div  v-if="chart_data.chart_nds!=undefined">
           <h4>{{area_name_jp}}</h4>
            <div class="tab-box" >
                <ul class="tab-head nav nav-tabs">
                  <li class="nav-item" data-id="tc1" @click="tab3('tc1')" v-bind:class="[ccurindex=='tc1' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab"  class="nav-link" aria-expanded="true"> <div class="lang" v-html="lan.building_base" ></div></a>
                  </li>
                  <li class="nav-item" data-id="tc6" @click="tab3('tc6')" v-bind:class="[ccurindex=='tc6' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab"  class="nav-link" aria-expanded="true"> <div class="lang" v-html="lan.face_new_average" ></div></a>
                  </li>
                  <li  class="nav-item" data-id="tc2" @click="tab3('tc2')" v-bind:class="[ccurindex=='tc2' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.vacancy_rate" ></div></a>
                  </li>  
                  <li  class="nav-item" data-id="tc2" @click="tab3('tc3')" v-bind:class="[ccurindex=='tc3' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.new_supply_net_absorption" ></div></a>
                  </li>  
                  <li  class="nav-item" data-id="tc2" @click="tab3('tc4')" v-bind:class="[ccurindex=='tc4' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab" class="nav-link" aria-expanded="true"> <div class="lang" v-html="lan.major_new_supply_projects" ></div></a>
                  </li>  
                  <li  class="nav-item" data-id="tc2" @click="tab3('tc5')" v-bind:class="[ccurindex=='tc5' ? 'nav-item active show':'nav-item']">
                    <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.major_future_projects" ></div></a>
                  </li>  
                
                </ul>
                <div class="help"><img :src="'../img/help.svg'" @click="isPopupHelp=true"></div><transition name="fade">
          <div v-show="isPopupHelp" class="modal popup-modal">
            <div class="modal-dialog">
              <div class="popup modal-content help-box">
                <div class="modal-header">
                  <button type="button" class="close" @click="isPopupHelp = false">
                    <span>×</span>
                  </button>
                </div>
                <div class="modal-body" v-if="isPopupHelp">
               
                <div class="lang" v-html="lan.help_chart"></div>
                </div>
              </div>
            </div>
            <div class="popup-bg" @click="isPopupHelp = false"></div>
          </div>
        </transition>
                
            </div>
            <div class="tab-content" style="padding: 15px;background:#fff">
            <div class="embed-responsive-16by9 tab-pane show active tc1"  v-if="ccurindex=='tc1'">
                  <div class="col-12">
                    <div class="card-box">
                        <div v-html="chart_data.building_area_description"></div>
                    </div>
                  </div>
              </div>
             <div class="embed-responsive-16by9 tab-pane show active tc6"  v-if="ccurindex=='tc6'">
                  <div class="col-12">
                    <div class="card-box">
                        <iframe class="embed-responsive-item" :src="chart_data.chart_rent[cur_lan]" loading="lazy">
                        </iframe> 
                    </div>
                  </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc2"  v-if="ccurindex=='tc2'">
                  <div class="col-12">
                    <div class="card-box">
                        <iframe class="embed-responsive-item" :src="chart_data.chart_vc[cur_lan]" loading="lazy">
                        </iframe> 
                    </div>
                  </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc3"  v-if="ccurindex=='tc3'">
                  <div class="col-12">
                    <div class="card-box">
                        <iframe class="embed-responsive-item" :src="chart_data.chart_nds[cur_lan]" loading="lazy">
                        </iframe> 
                    </div>
                  </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc4"  v-if="ccurindex=='tc4'">
                  <div class="col-12">
                   
                  <div class="card-box">
                    <div class="tab-box" >
                      <ul class="tab-head nav nav-tabs">
                          <li class="nav-item active" data-id="mnsp1"><a href="#mnsp1" data-toggle="tab" aria-expanded="true" class="nav-link active"> <div class="lang" v-html="lan.major_new_supply_projects" ></div></a></li>
                          <li class="nav-item" data-id="mnsp2"><a href="#mnsp2" data-toggle="tab" aria-expanded="false" class="nav-link" ><div class="lang" v-html="lan.map" ></div></a></li>
                      </ul>
                      <div class="tab-content">
                        <div class="embed-responsive tab-pane show active mnsp1"  id="mnsp1">
                          <div class="table-responsive2">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th style="width:32%"><div class="lang" v-html="lan.name"></div></th>
                                  <th style="width:40%"><div class="lang" v-html="lan.location"></div></th>
                                  <th style="width:16%"><div class="lang" v-html="lan.completion"></div></th>
                                  <th style="width:12%"><div class="lang" v-html="lan.gfa_"></div></th>
                                  
                                </tr>
                              </thead>
                              <tbody style="max-height:580px;">
                                <tr class="" v-for="(obj, idNum) in listDataMNSP" :key="idNum"  >
                                <td :title="idNum2<4 ? obj2:''" :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }" v-for="(obj2, idNum2) in obj" :key="idNum2">  
                                  {{(parseFloat(obj2)!=NaN && idNum2==6 ? formart_money(obj2):obj2)}}
                                </td>
                                </tr>
                                
                              </tbody>
                                <tbody v-if="!listDataMNSP.length">
                                  <tr class="p-2 no-data" >
                                    <td colspan="4"><code ><div class="lang" v-html="lan.no_data"></div></code></td>
                                  </tr>
                                </tbody>
                            </table>
                            <span><div class="lang" v-html="lan.area_unit"></div></span>
                          </div> <!-- end .table-responsive -->
                              
                        </div>
                        <div class="embed-responsive tab-pane mnsp2"   id="mnsp2">
                            <div id="map_mnsp" class="google_maps"></div>
                            
                        </div>
                      </div>
              </div>
             </div>
             </div>
            </div>
         
            
          
              <div class="embed-responsive-16by9 tab-pane show active tc5"  v-if="ccurindex=='tc5'">
                  <div class="col-12">

                    <div class="card-box">
                      <div class="tab-box">
                                <ul class="tab-head nav nav-tabs">
                                    <li class="nav-item active" data-id="mfp1"><a href="#mfp1" data-toggle="tab" aria-expanded="true" class="nav-link active"> <div class="lang" v-html="lan.major_future_projects" ></div></a></li>
                                    <li class="nav-item" data-id="mfp2"><a href="#mfp2" data-toggle="tab" aria-expanded="false" class="nav-link"><div class="lang" v-html="lan.map" ></div></a></li>
                                </ul>
                                <div class="tab-content">
                                  <div class="embed-responsive tab-pane show active mfp1"  id="mfp1">
                                    <div class="table-responsive2">
                                      <table class="table table-striped focus-on">
                                        <thead>
                                          <tr>
                                            <th style="width:32%"><div class="lang" v-html="lan.name"></div></th>
                                            <th style="width:40%"><div class="lang" v-html="lan.location"></div></th>
                                            <th style="width:16%"><div class="lang" v-html="lan.completion"></div></th>
                                            <th style="width:12%"><div class="lang" v-html="lan.gfa_"></div></th>
                                          </tr>
                                        </thead>
                                        <tbody  style="max-height:580px;">
                                          <tr class="" v-for="(obj, idNum) in listDataMFP" :key="idNum" >
                                          <td :title="idNum2<4 ? obj2:''" :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }" v-for="(obj2, idNum2) in obj" :key="idNum2">  
                                            {{(parseFloat(obj2)!=NaN && idNum2==6 ? formart_money(obj2):obj2)}}
                                          </td>
                                          </tr>
                                          
                                        </tbody>
                                        <tbody v-if="!listDataMFP.length">
                                          <tr class="p-2 no-data" >
                                            <td colspan="4"><code ><div class="lang" v-html="lan.no_data"></div></code></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <span><div class="lang" v-html="lan.area_unit"></div></span>
                                    </div> <!-- end .table-responsive -->
                                      
                                  </div>
                                  <div class="embed-responsive  tab-pane mfp2"   id="mfp2">
                                      <div id="map_mfp" class="google_maps"></div>
                                      
                                  </div>
                                </div>
                        </div>
                    </div>
      
                  </div>
                </div>
               
               
              </div>
             </div>
          </div>
          <div class="embed-responsive-16by9 tab-pane show active tc5"  v-if="curindex=='tc5'">
              <div class="col-12">
                <div class="card-box rent-info" v-if="cur_space_id!=''">
                <list-article2 :viewParams="viewParamsRentInfo" />
                </div>
                <div class="card-box rent-list" v-if="cur_space_id==''">
                      <div class="responsive-table-plugin" v-if="!jsonErr6">

                        <div class="table-rep-plugin">
                          <div class="table-responsive">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th v-for="(tableHeader,key) in tableHeaders5" :key="key" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th>
                                 
                                </tr>
                              </thead>
                              <tbody v-if="jsonObj5.total_count>0">
                                  <tr v-for="(obj, idNum) in listData5" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="gotoRentInfo(obj.space_id,obj.space_class)">
                                    
                                    <list-table-row :row="obj" />
                                    
                                  </tr>
                                </tbody>
                            </table>
                            <div class="p-2">
                              <code v-if="!jsonObj5.total_count"><div class="lang" v-html="lan.no_data"></div></code>
                            </div>
                          </div> <!-- end .table-responsive -->
                        </div> <!-- end .table-rep-plugin-->

                        <div class="d-flex justify-content-end mt-1" v-if="jsonObj5.total_count">
                          <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
                          &nbsp;~&nbsp;
                          <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj5.count }}</span>
                          &nbsp;/&nbsp;
                          <span>{{ jsonObj5.total_count }}</span>
                        </div>
                        <div class="d-flex justify-content-end mt-1" v-else>
                          <span>0</span>
                          &nbsp;~&nbsp;
                          <span>0</span>
                          &nbsp;/&nbsp;
                          <span>0</span>
                        </div>

                        <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1" v-if="jsonObj.total_count">
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('first')">«</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a></li>
                          <li class="footable-page mb-1" :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }" v-for="num in maxPage" :key="num"><a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('next')">›</a></li>
                          <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('last')">»</a></li>
                        </ul>
                      </div> <!-- end .responsive-table-plugin-->
                      <div v-else>
                        <code>
                        データ取得に失敗しました。
                        </code>
                      </div>
                </div>
              </div>
          </div>
         <div class="embed-responsive-16by9 tab-pane show active tc1" v-if="curindex=='tc1'">
              <div class="col-12">
                <div class="card-box">
                  <div class="row" style="width:80%;margin:5px auto;">
                    <div class="col-6 piclist" >
                        <carousel :items-to-show="1" v-if="BuildingPic.length>0">
                            <slide v-for="(item,slide) in BuildingPic" :key="slide">
                              <img :src="item" >
                            </slide>

                            <template #addons>
                              <navigation />
                              <pagination />
                            </template>
                          </carousel>
                    </div>
                    <div class="col-6">
                      <div class="tab-box" >
                                    <ul class="tab-head nav nav-tabs">
                                      <li class="nav-item" data-id="tc1" @click="tab2('tc1')" v-bind:class="[bcurindex=='tc1' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.building_base" ></div></a>
                                      </li>
                                      <li  class="nav-item" data-id="tc2" @click="tab2('tc2')" v-bind:class="[bcurindex=='tc2' ? 'nav-item active show':'nav-item']">
                                        <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.building_ext" ></div></a>
                                      </li>  
                                      
                                     
                                    </ul>
                                    
                                </div>
                                <div class="tab-content" style="width:100%;background:#fff">
       
                        <div class="embed-responsive-16by9 tab-pane show active tc1" v-if="bcurindex=='tc1'">
                        <div class="table-rep-plugin" >
                          <div class="" data-pattern="priority-columns" >
                            <table class="table table-borderless" >
                              <tbody >
                               <tr v-for="(value, keyName) in BuildingInfo" :key="keyName" v-show="checkKeys.includes(keyName)">
                                <td v-if="keyName=='PublicTransportationMetro_1'">{{lan['PublicTransportationMetro']}}</td>
                                <td v-if="keyName=='FlrsAbvGrnd'">{{lan['FlrsAbvBlwGrnd']}}</td>
                                
                                
                                <td v-if="keyName!='FlrsAbvGrnd' && keyName!='PublicTransportationMetro_1'">{{lan[keyName]}}</td>
                                <th v-if="keyName=='PublicTransportationMetro_1'">
                                  {{BuildingInfo['PublicTransportationMetro_1']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']+'分':'')}}<br>
                                  {{BuildingInfo['PublicTransportationMetro_2']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']+'分':'')}}<br>
                                  {{BuildingInfo['PublicTransportationMetro_3']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']+'分':'')}}
                                  </th>
                                  <th v-if="keyName=='Typical_Minimum_FloorSize'">
                                  {{formart_money(BuildingInfo['Typical_Minimum_FloorSize'])}}坪 / {{formart_money(BuildingInfo['Typical_Minimum_FloorSize_SqM'])}}m2
                                  </th>
                                   <th v-if="keyName=='TotalGFA_SqM'">
                                    {{formart_money(BuildingInfo['TotalGFA'])}}坪 / {{formart_money(BuildingInfo['TotalGFA_SqM'])}}m2
                                  </th>
                                  <th v-if="keyName=='FlrsAbvGrnd'">
                                  {{lan['FlrsAbvBlwGrndValue'].replace('{abv}',BuildingInfo['FlrsAbvGrnd']).replace('{blw}',BuildingInfo['FlrsBlwGrnd'])}}
                                  </th>
                                  <th v-if="keyName!='FlrsAbvGrnd' && keyName!='TotalGFA_SqM' && keyName!='Typical_Minimum_FloorSize' && keyName!='PublicTransportationMetro_1'">{{formart_date(value)+(keyName=='BuildingName'?'('+BuildingInfo.BuildingID+')':'' )}}</th>
                                </tr>
                              </tbody>
                          </table></div><!-- end .table-responsive --></div>
                        </div>
                        <div class="embed-responsive-16by9 tab-pane show active tc2" v-if="bcurindex=='tc2'">
                        <div class="table-rep-plugin" >
                          <div class="" data-pattern="priority-columns" >
                            <table class="table table-borderless" >
                              <tbody >
                               <tr v-for="(value, keyName) in BuildingInfo.building_ext" v-show="!checkKeys_ext.includes(keyName)"  :key="keyName">
                               
                                <td>{{keyName}}</td>
                                <th>{{value}}{{(keyName=='エレベーター数' ?'基':'')}}</th>
                                 
                                </tr>
                              </tbody>
                          </table></div><!-- end .table-responsive --></div>
                        </div>
                      </div>  
                    </div>
                  </div>
                  
                  <google-map v-if="!isLoading" :address="BuildingInfo.Address" :reload="reloadCount" />
                  <div class="row" v-else>
                    <div class="col-6">google-map
                      <div class="page-title-box">
                        <h4 class="page-title">Google Map</h4>
                      </div>
                      <div class="card-box mb-2">
                        <!-- map -->
                        <div class="d-flex justify-content-center p-5">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="page-title-box">
                        <h4 class="page-title">Google Street View</h4>
                      </div>
                      <div class="card-box mb-2">
                        <!-- streetview -->
                        <div class="d-flex justify-content-center p-5">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="page-title-box">
                        <h4 class="page-title">Rader Chart</h4>
                      </div>
                      <div class="card-box mb-2">
                        <div class="">
                        <apexchart type="radar" height="500px" width="100%" :options="options" :series="series"></apexchart>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="page-title-box">
                        <h4 class="page-title">物件の特徴</h4>
                      </div>
                      <div class="card-box mb-2">
                        <!-- rangeslider -->
                        <div v-if="!isLoading3">
                          <div class="mb-3" v-for="(obj, num) in rangeKeys" :key="num">
                            <div v-if="jsonLang[lang][obj]">
                              <h4 class="header-title mb-1">{{ jsonLang[lang][obj] }}</h4>
                              <range-slider :id="obj" :from="extendParams.extended[obj]" @updateRange="UpdateRange"/>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center p-5" v-else>
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="page-title-box">
                        <h4 class="page-title">他の人のコメント<span>({{extendParams.extended_memo_others.length}})</span></h4>
                      </div>
                      <div class="card-box mb-2">
                        <!-- other comments -->
                        <div v-if="!isLoading3">
                          <div class="carousel" v-if="extendParams.extended_memo_others.length > 0">
                            <div class="d-flex other-comments-container">
                              <button type="button" class="btn btn-light other-control other-control-prev" @click.prevent.stop="OtherComments('prev')" v-if="extendParams.extended_memo_others.length > 1">
                                <i class="mdi mdi-chevron-double-left" aria-hidden="true"></i>
                              </button>
                              <div class="other-item card-body" :id="'memo-' + otherCount">
                                <div v-if="extendParams.extended_memo_others[otherCount].memo" v-html="extendParams.extended_memo_others[otherCount].memo"> </div>
                                <div v-else class="text-secondary">コメント未入力</div>
                                <span style="color:#ccc;position:absolute;right:5px;bottom:5px;">日付：{{extendParams.extended_memo_others[otherCount].updated_at}}</span>
                              </div>
                              <button type="button" class="btn btn-light other-control other-control-next" @click.prevent.stop="OtherComments('next')" v-if="extendParams.extended_memo_others.length > 1">
                                <i class="mdi mdi-chevron-double-right" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <p class="text-muted">コメントがありません。</p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center p-5" v-else>
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="page-title-box">
                        <h4 class="page-title">コメント</h4>
                      </div>
                      <div class="card-box mb-2">
                        <!-- comment -->
                        <div v-if="!isLoading3">
                          <textarea class="form-control" rows="10" v-model="extendParams.extended.memo"></textarea>
                        </div>
                        <div class="d-flex justify-content-center p-5" v-else>
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 btn-toolbar justify-content-end align-items-center row pr-0 pl-3">
                      <div class="col-11 mb-0 alert alert-primary" v-if="saveAlert === 1">ご意見有難う御座います。内容を確認してシステム上に反映します。</div>
                      <div class="col-11 mb-0 alert alert-danger" v-if="saveAlert === 9">保存に失敗しました。</div>
                      <div class="col-1 pr-0">
                        <button type="button" class="btn btn-primary" @click="SaveParams">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
//import ListArticleLease from '@/components/ListArticleLease.vue'
import ListTableRow from '@/components/ListTableRow.vue'
import router from '@/router'
import Headers from '@/components/Headers.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import ListArticle2 from '@/components/ListArticle2.vue'
import RangeSlider from '@/components/RangeSlider.vue'
//import ListCorpTable from '@/components/ListCorpTable.vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';



export default {
  name: 'CorpDetail',
  components: {
    //ListArticleLease,
    GoogleMap,
    RangeSlider,
    Headers,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ListTableRow,
    ListArticle2,
    //ListCorpTable,
  },
  data() {
    const { currentRoute } = router
    return {
     
      tolink:"/"+currentRoute.value.query.tolink,
      curlink: encodeURIComponent(window.location.href.split('#')[1]),
      LeaseInfo:{},
       viewParamsHistory: {
        name: 'corp_history',
        apiUrl: '/v1/getListByCropNum?cropNum='+ currentRoute.value.query.dataNumber,
        paramKey: 'ID',
      },
      cur_space_class:'',
      cur_space_id:'',
      viewParamsRentInfo: {
        name: 'rent_info',
        apiUrl: '/v1/building_rent_info?space_id='+this.cur_space_id+'&space_class='+ this.cur_space_class,
        paramKey: 'space_id',
      },
      PropertyCode:currentRoute.value.query.PropertyCode,
      BuildingID:currentRoute.value.query.BuildingID,
      BuildingID_to:198117,
      LeaseID:currentRoute.value.query.LeaseID,
      BuildingInfo:{},
      BuildingPic:[],
      BuildingInfo_to:{},
      BuildingPic_to:[],
      // company
       viewParams_company: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType:100,
      },
      viewParams: {
        name: 'build1',
        apiUrl: '/v1/properties1/' + currentRoute.value.query.BuildingID,
        apiUrl_to: '/v1/properties1/198117',
        paramKey: 'BuildingID',
      },
     
      corp_num:'',
      viewParamsOffice: {
        name: 'corp_offices2',
        apiUrl: '/v1/corpsoffices/100?cid=100&corp_num=1',
        paramKey: 'ID',
        randIndex:0,
      },
      raderChartURL: process.env.VUE_APP_PRP_CHART_URL_BASE + currentRoute.value.query.BuildingID + '.html',
      jsonObj: [Object],
      jsonObj5: [Object],
      jsonObjTypes: [Object],
      jsonObj_to: [Object],
      jsonObjTypes_to: [Object],
      isLoading: true,
      jsonErr: false,
      articleTableEdit: false, // true = edit table
      extendParams: {
        extended: {},
        extended_memo_self: {},
        extended_memo_others: [],
      },
       extendParams_to: {
        extended: {},
        extended_memo_self: {},
        extended_memo_others: [],
      },
      rangeKeys_to: [],
      rangeKeys: [],
      jsonLang: {},
      lang: 'ja',
      saveAlert: 0, // 保存結果
      otherCount: 0,
      reloadCount: 0, // map再読み込み用
      reloadCount_to: 0, // map再読み込み用
      editAlert: false,
      options:{},
       options_to:{},
      series:[],
      series_to:[],
      isLoading3:true,
      curindex:'tc1',
      bcurindex:'tc1',
      ccurindex:"tc1",
      bcurindex_to:'tc1',
      cur_lan:localStorage.getItem("cur_lan"),
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      isattr:0,
      isattr_to:0,
      checkKeys:['Address',
      'Typical_Minimum_FloorSize',
      'TotalGFA_SqM',
      'BuildingName',
      'BuildingSectorsJA',
      'CompletionDate',
      'Grade',
      'PublicTransportationMetro_1',
      //'PublicTransportationMetro_2',
      //'PublicTransportationMetro_3'
      ],
      checkKeys_ext:['最寄り駅徒歩時間(分)1','最寄り駅徒歩時間(分)2','最寄り駅徒歩時間(分)3'],
      market_url:'',
      //list lease 
      offset: 0,
      jsonErr5:false,
      jsonErr6:false,
      listStrage: {
        query: '',
        limit: 50,
        paged: 1,
      },
      maxPage: 0,
      checkKeys2: [],
      BuildingName: '',
      jsonObj2: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders: [],
      listData: [Object],
      chart_data:{},
      area:'',
      area_name_jp:'',
      isPopupHelp:0,
      // building list：
      listDataMNSP:[],
      listDataMFP:[],
       map:[null,null],
        mapContainer:[null,null],
        markers:[],
        infowindow:[],
        google: {
          maps: {
            StreetViewPanorama: {},
            Map: {},
            MapTypeId: {},
            Marker:{},
            InfoWindow:{},
            Polygon:{},
          },
        },
      chart_polygon:null,
      
    } 	
  },
  mounted() {
     //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    this.getBuildingRentInfo();
    this.getBuildingPic();
    this.getBuildingInfo();

  },
  methods: {
    goto(corp_number){
      console.log('corp_number'+corp_number)
    },
    gotoRentInfo(space_id,space_class){
      this.cur_space_id=space_id;
      this.cur_space_class=space_class;
      this.viewParamsRentInfo={
        name: 'rent_info',
        apiUrl: '/v1/building_rent_info?space_id='+this.cur_space_id+'&space_class='+ this.cur_space_class,
        paramKey: 'space_id'
      };

    },
    backRentList(){
      this.cur_space_id='';
      this.cur_space_class='';
    },
     formart_money:function (num) {
      var result = '', counter = 0;
      num = (num || 0).toString();
      var numa=num.split('.');
      var d=0;
      if(numa[1]!=undefined){
        d=numa[1].length+1;
      }

      for (var i = num.length-d - 1; i >= 0; i--) {
      counter++;
      result = num.charAt(i) + result;
      if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
      }
      return d>0 ?result+'.'+numa[1]:result;
    },
     formart_date:function (num) {
      
      return typeof(num)=='string' ? num.replace(' 00:00:00',''):num;
    },
    
    ToggleArticleTable: function(opt) {
      this.articleTableEdit = !this.articleTableEdit
      if(opt === 'save') {
        document.getElementById('article_table').scrollTo({
          top: 0,
        });
        window.scrollTo({
          top: 0,
        });
        this.editAlert = true
      } else {
        this.editAlert = false
      }
    },
    UpdateData: function(data) {
      this.jsonObj = data
      this.reloadCount++
    },

    getBuildingInfo_to(){
      var that=this;
      axios
        .get('/v1/building_info?BuildingID=' + this.BuildingID_to)
        .then((dd) => {
          that.BuildingInfo_to = dd.data;
          console.log(that.BuildingInfo_to)
          that.isLoading=false;
          //that.market_url=getareaurl(that.BuildingInfo_to.Address);
        })
        .catch(error => {
        
          console.log(error)
        });
    },
    getchartmap(area){
      var that=this;
      axios
        .get(process.env.VUE_APP_API2_URL_BASE+'/getMapChartByArea?area_map_name=' + area)
        .then((dd) => {
          that.chart_data=dd.data.data;
         console.log(that.chart_data.chart_nds[that.cur_lan]);
          that.isLoading3=false;
        })
        .catch(error => {
        
          console.log(error)
        });
    },
    getareabyaddress(address){
      var that=this;
      axios
        .get(process.env.VUE_APP_API2_URL_BASE+'/getMapByAddress?address=' + address)
        .then((dd) => {
          
          if(dd.data.data!=''){
            that.area_name_jp=dd.data.data;
            that.getchartmap(dd.data.data);
          }
          
        })
        .catch(error => {
        
          console.log(error)
        });
    },
    getBuildingInfo(){
      var that=this;
      axios
        .get('/v1/building_info?BuildingID=' + this.BuildingID)
        .then((dd) => {
          that.BuildingInfo = dd.data;
          that.BuildingName=that.BuildingInfo.BuildingName;
          console.log(that.BuildingInfo);
          that.isLoading=false;
          that.getareabyaddress(dd.data.Address);
          that.getLeaseInfo();
        })
        .catch(error => {
        
          console.log(error)
        });
    },
    getLeaseInfo(){
       axios
          .get('/v1/corps_group', {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              data_type: 'rent', //表示类型
              q: this.listStrage.query, //キーワード
              CorpName: this.CorpName,
              BuildingName: this.BuildingName,
              id_token:localStorage.getItem('id_token'),
            }
          })
          .then(response => (
              this.jsonObj2 = response.data,
              this.tableHeaders = this.jsonObj2.headers,
              this.listData = this.jsonObj2.data,
              this.checkKeys2 =["Name","OfficePhone","FloorNumber","TotalArea"],
              this.maxPage = Math.ceil(this.jsonObj2.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj2.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
            console.log(error),
            this.jsonErr5 = true,
            this.isLoading = false
            }
          );
    },
    getBuildingRentInfo(){
      
       axios
          .get('/v1/building_list', {
            params: {
              offset: 0, //開始位置
              limit: 1000, //表示件数
              type: 'building_rent', //表示类型
              property_id: this.PropertyCode, //キーワード
              id_token:localStorage.getItem('id_token'),
            }
          })
          .then(response => (
            console.log(response.data),
              this.jsonObj5 = response.data,
              this.tableHeaders5 = this.jsonObj5.headers,
              this.listData5 = this.jsonObj5.data,
              this.isLoading = false
            )
          )
          .catch(error => {
            console.log(error),
            this.jsonErr6 = true,
            this.isLoading = false
            }
          );
    },
    getBuildingPic(){
      var that=this;
      axios
        .get('/v1/building_pic?BuildingID=' + this.BuildingID)
        .then((dd) => {
          that.BuildingPic = dd.data.data;
          console.log(that.BuildingPic)
        })
        .catch(error => {
          
          console.log(error)
        });
    },
    getBuildingPic_to(){
      var that=this;
      axios
        .get('/v1/building_pic?BuildingID=' + this.BuildingID_to)
        .then((dd) => {
          that.BuildingPic_to = dd.data.data;
          console.log(that.BuildingPic_to)
        })
        .catch(error => {
          
          console.log(error)
        });
    },
    SaveParams: function() {
      this.saveAlert = 0
      axios
        .put(this.viewParams.apiUrl + '/extended', {
          extended: this.extendParams.extended,
          email: localStorage.getItem('email'),
          id_token:localStorage.getItem('id_token')
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
    SaveParams_to: function() {
      this.saveAlert = 0
      axios
        .put(this.viewParams.apiUrl + '/extended', {
          extended: this.extendParams_to.extended,
          email: localStorage.getItem('email'),
          id_token:localStorage.getItem('id_token')
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
    RangeKeysFilter: function() {
     
        let Ary = Object.keys(this.extendParams.extended)
        //'feature_'からはじまるキー
        this.rangeKeys = Ary
        this.rangeKeys = Ary.filter( key => key.startsWith('feature_') )
        console.log(this.rangeKeys)
        var cats=[];
        var dt=[];
        for(var d in this.rangeKeys){
          cats.push(this.jsonLang[this.lang][this.rangeKeys[d]]);
          dt.push(this.extendParams.extended[this.rangeKeys[d]]);
        }
        console.log(cats)
        console.log(dt)
        this.series= [{
              name: '物件の特徴',
              data: dt,
            }];
            this.options={
              chart: {
                height: 500,
                type: 'radar',
              },
              
              xaxis: {
                categories: cats
              },
            };
          console.log('charts'); 
        
    },
     RangeKeysFilter_to: function() {
    
       let Ary = Object.keys(this.extendParams_to.extended)
      //'feature_'からはじまるキー
      this.rangeKeys_to = Ary
      this.rangeKeys_to = Ary.filter( key => key.startsWith('feature_') )
      var cats=[];
      var dt=[];
      for(var d in this.rangeKeys_to){
        cats.push(this.jsonLang[this.lang][this.rangeKeys_to[d]]);
        dt.push(this.extendParams_to.extended[this.rangeKeys_to[d]]);
      }
      
       this.series_to= [{
            name: '物件の特徴',
            data: dt,
          }];
          this.options_to={
            chart: {
              height: 500,
              type: 'radar',
            },
            
            xaxis: {
              categories: cats
            },
          };
         console.log('charts_to'); 
       
    },
    UpdateRange(res) {
      this.extendParams.extended[res.id] = res.data.from
    },
    UpdateRange_to(res) {
      this.extendParams_to.extended[res.id] = res.data.from
    },
    OtherComments: function(key) {
      if(key === 'next') {
        if(this.extendParams.extended_memo_others[this.otherCount + 1]) {
          this.otherCount++
        } else {
          this.otherCount = 0
        }
      } else {
        if(this.otherCount !== 0) {
          this.otherCount--
        } else {
          this.otherCount = this.extendParams.extended_memo_others.length - 1
        }
      }
    },
    tab:function(tid){
      this.curindex=tid;
      if(tid=='tc2' && this.isattr==0){
       this.loadattr();
      } 
    },
    tab2:function(tid){
      this.bcurindex=tid;
    },tab2_to:function(tid){
      this.bcurindex_to=tid;
    },tab3:function(tid){
      this.ccurindex=tid;
      if(tid=='tc4' || tid=='tc5'){
          if(window.google==undefined){
            this.loadGoogleMapsScript().then(() => {
              this.google = window.google;
              //this.initializeMap();
              if(tid=='tc5'){
                this.getFutureSupplyProject();
              }else{
                this.getNewSupplyProject();
              }
            });
          }else{
            this.google = window.google;
            if(tid=='tc5'){
              this.getFutureSupplyProject();
            }else{
              this.getNewSupplyProject();
            }
          }
          
           
            
      }
      
    },loadattr_to(){
      var that=this;
      if(this.isattr_to==0){
        this.isLoading4=true;
       this.jsonLang = require('@/assets/languages/ListArticle.json')
    axios
      .get(this.viewParams.apiUrl_to)
      .then(response => {
        that.isattr_to=1;
          that.jsonObj_to = response.data.data
          that.jsonObjTypes_to = response.data.types
          if(response.data.extended!=null && response.data.extended.length>0) {
            that.extendParams_to.extended = response.data.extended
          } else {
            that.extendParams_to.extended = {
              Address: that.jsonObj.address==undefined ? that.jsonObj.Address:that.jsonObj.address,
              BuildingID: that.jsonObj.BuildingID,
              feature_1: 0,
              feature_2: 0,
              feature_3: 0,
              feature_4: 0,
              feature_5: 0,
              feature_6: 0,
              feature_7: 0,
              feature_8: 0,
            }
          }
          if(response.data.extended_memo_self !=null) {
            that.extendParams_to.extended.memo = response.data.extended_memo_self.memo
          } else {
            that.extendParams_to.extended.memo = null;
          }
          if(response.data.extended_memo_others.length>0) {
            that.extendParams_to.extended_memo_others = response.data.extended_memo_others
          }
          that.RangeKeysFilter_to()
        
          that.isLoading4 = false;
      })
      .catch(error => (
          console.log(error),
          that.jsonErr = true,
          that.isLoading4 = false
        )
      );
      }
    },
    loadattr(){
      var that=this;
      if(this.isattr==0){
        this.isLoading3=true;
       this.jsonLang = require('@/assets/languages/ListArticle.json')
    axios
      .get(this.viewParams.apiUrl)
      .then(response => {
        that.isLoading3 = false
        that.isattr=1;
          that.jsonObj = response.data.data
          that.jsonObjTypes = response.data.types
          if(response.data.extended!=null && response.data.extended.length>0) {
            that.extendParams.extended = response.data.extended
          } else {
            that.extendParams.extended = {
              Address: that.jsonObj.address==undefined ? that.jsonObj.Address:that.jsonObj.address,
              BuildingID: that.jsonObj.BuildingID,
              feature_1: 0,
              feature_2: 0,
              feature_3: 0,
              feature_4: 0,
              feature_5: 0,
              feature_6: 0,
              feature_7: 0,
              feature_8: 0
            }
          }
          if(response.data.extended_memo_self !=null) {
            that.extendParams.extended.memo = response.data.extended_memo_self.memo
          } else {
            that.extendParams.extended.memo = null;
          }
          if(response.data.extended_memo_others.length>0) {
            that.extendParams.extended_memo_others = response.data.extended_memo_others
          }
          console.log(that.extendParams.extended);
          that.RangeKeysFilter()
        
      })
      .catch(error => (
          console.log(error),
          that.jsonErr = true,
          that.isLoading3 = false
        )
      );
      }
    },
    backListener () {
      this.$refs.myEnergy.destroySelf()
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
  
    initMaps(mapd,coordinatesData,Polygon) {
     
      const strokeColors = ["#2e344e","#d18802", "#2bb4af", "#5b18c2", "#acce87", "#007cf5", "#ffe303", "#ff0352", "#00ff00", "#ff00f5", "#ffacb5"];
      const fillColors = ["#2e344e","#d18802","#2bb4af", "#5b18c2","#acce87","#007cf5","#ffe303","#ff0352","#00ff00","#ff00f5","#ffacb5",];

      const coordDate = JSON.parse(coordinatesData);
      const coordinates = coordDate;
      for(var d in coordinates){
        for(var dd in coordinates[d]){
          coordinates[d][dd].lat=parseFloat(coordinates[d][dd].lat);
          coordinates[d][dd].lng=parseFloat(coordinates[d][dd].lng);
        }
      }
      console.log(coordinates[0][0])
      mapd.setCenter(coordinates[0][0]);
      for (let index = 0; index < coordinates.length; index++) {

          const drawPolygon = new Polygon({
              paths: coordinates[index],
              strokeColor: strokeColors[index],
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: fillColors[index],
              fillOpacity: 0.35,
          });

          drawPolygon.setMap(mapd);
          
      }
      return mapd;
    
  },
    initializeMap(mapname) {
      if(this.google.maps.Map.length==0){
        console.log('not load');
        return false;
      }
      var _that=this;
      var mapList=[];
      var curid=0;
      if(mapname=="mfp"){
        mapList=_that.listDataMFP
        curid=0
      }else{
        mapList=_that.listDataMNSP
        curid=1
      }
      //this.$el.querySelector("#map_"+mapname).innerHTML="";
      this.mapContainer[curid] = document.querySelector("#map_"+mapname);
      
      const { Map, Marker,MapTypeId,InfoWindow,Polygon} = this.google.maps;
      //const { Map, Marker,MapTypeId,InfoWindow} = this.google.maps;
    
      
      var cgps=[0,0];
      var zoom=13
      if(mapList.length>0){

          mapList.map((obj) => {
            cgps[0]+=parseFloat(obj[8]);
            cgps[1]+=parseFloat(obj[7]);
          })

      // map create
      
      _that.map[curid] = new Map(this.mapContainer[curid], {
          zoom: zoom,
          mapTypeId:  MapTypeId.ROADMAP,
        });
        
      if(this.chart_polygon!=null && this.chart_polygon.length>0){
        _that.map[curid]=this.initMaps(_that.map[curid],this.chart_polygon,Polygon);

      }else{
        // set locate
          _that.map[curid].setCenter({
            lat: parseFloat((cgps[0]/mapList.length).toFixed(5)),
            lng: parseFloat((cgps[1]/mapList.length).toFixed(5)),
          });
      }
      //new Geocoder.geocode({ address: _that.area_name }, function (results, status) {
      //  if (status === "OK" && results[0]) {
       
          _that.markers[curid]=[];
          _that.infowindow[curid]=[];
          
            mapList.map((obj,i) => {
                _that.infowindow[curid][i] = new InfoWindow({
                  content: '<div id="content">' +
                              '<div id="siteNotice">' +
                              "</div>" +
                              '<h3 id="firstHeading" class="firstHeading">'+(_that.cur_lan!='en' ?obj[0]:obj[1])+'</h3>' +
                              '<div id="bodyContent">' +
                              "<p>"+_that.lan.location+" ："+(_that.cur_lan!='en' ?obj[2]:obj[3])+"<br>"+_that.lan.completion+" ："+obj[4]+"<br>"+_that.lan.gfa_+" ："+(isNaN(obj[6])==false ? _that.formart_money(obj[6]):obj[6])+"</p>" +
                              "</div>" +
                              "</div>"
                });
                _that.markers[curid][i]=new Marker({
                  position: {
                            lat: parseFloat(obj[8]),
                            lng: parseFloat(obj[7])},
                  map:_that.map[curid],
                  title: (_that.cur_lan!='en' ?obj[0]:obj[1]),
                  icon: "https://cbredata.s3.ap-northeast-1.amazonaws.com/images/lou.png",
                });
                _that.markers[curid][i].addListener("click", () => {
                  _that.infowindow[curid][i].open({
                    anchor: _that.markers[curid][i],
                    map:_that.map[curid],
                    shouldFocus: false
                  });
                });

            });      
      }else{
        
        _that.map[curid] = new Map(this.mapContainer[curid], {
          zoom: 11,
          mapTypeId:  MapTypeId.ROADMAP,
        });
        
     
        // set locate
          _that.map[curid].setCenter({
            lat: parseFloat((_that.current_gps.split(',')[0]).toFixed(5)),
            lng: parseFloat((_that.current_gps.split(',')[1]).toFixed(5)),
          });
      
      }            
        
    },
     getFutureSupplyProject:function (){
      
      this.isLoading=true;
      var that=this;
      axios.get(process.env.VUE_APP_API2_URL_BASE+'/getFutureSupplyProject', {
        params: {
          'area': this.area_name_jp
        }
      }).then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.chart_polygon=response.data.data.chart_polygon;
              that.listDataMFP = response.data.data.data;
              
              that.initializeMap('mfp');
              
              that.error_msg="";
              
            }else{
              that.suc_msg="";
              that.error_msg=response.data.msg;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }, getNewSupplyProject:function (){
      
      this.isLoading=true;
      var that=this;
      axios.get(process.env.VUE_APP_API2_URL_BASE+'/getNewSupplyProject', {
        params: {
          'area': this.area_name_jp
        }
      }).then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.chart_polygon=response.data.data.chart_polygon;
              that.listDataMNSP = response.data.data.data;
              
              that.initializeMap('mnsp');
      
              that.error_msg="";
            }else{
              that.suc_msg="";
              that.error_msg=response.data.msg;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }

  },beforeUnmount () {
    window.removeEventListener('pagehide', this.backListener)
  },

 
}
</script>
<style lang="scss">
.rent-list .table-striped tr td:nth-child(1){    text-decoration: underline;}
.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  text-align: left;
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: .5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem .25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: .15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
.piclist .carousel{
  height: 640px;
  width: 90%;
}
.carousel__prev, .carousel__next{
  background: #012a2d;
}
.carousel__pagination-button--active{
  color: #012a2d;
  background: #012a2d;
}
.carousel__slide{margin: 0 auto;width: 100% !important;}
.table-borderless tr{border-bottom: 1px solid #ddd;}
.carousel__slide img{
  max-width: 100%;
  max-height: 100%;
}
.responsive-table-plugin tbody th{
  width: 25%;
  font-weight: bolder !important;
}
.market_url{width: 100%;height: 800px;border: 0;}
.embed-responsive{min-height:540px;}
.embed-responsive-item{width: 100%;height:540px;border:0;}
.popup-modal {
    display: block;
  }
  .popup-bg {
    background: rgba(0, 0, 0, .25);
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    z-index: 9;
  }

  .popup {
    background: #fff;
    border-radius: .15rem;
    box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
    left: 50% !important;
    max-height: 85vh;
    min-height: 750px;
    width: 850px;
    position: fixed;

    transform: translateX(-50%) !important;
    z-index: 16;
  }

  .popup iframe {
    width: 100%;
  }
 
  .help{position: absolute;
    top: 5px;
    right: 20px;    display: inline-block;}
.help-box{margin-top:10%;width: 80%;}
.tab-box{position:relative;}
.hide{display: none;}
.google_maps{height: 540px;}
</style>